<template>
    <div class="container">
        <header class="jumbotron">
            <h3>Wybierz jednostke</h3>
        </header>
        <DataTable :value="content" v-model:filters="filters" :loading="loading" removableSort responsiveLayout="scroll"
                   :globalFilterFields="['name', 'place', ]">
            <template #header>
                <div class="p-d-flex p-jc-end">
                        <span class="p-input-icon-left ">
                            <i class="pi pi-search"/>
                            <InputText v-model="filters['global'].value" placeholder="Wyszukaj"/>
                        </span>
                </div>
            </template>
            <template #empty>
                Nie znalieziono jednostek
            </template>
            <template #loading>
                Ładwanie jednostek. Proszę czekać
            </template>
            <Column field="name" header="Nazwa" :sortable="true"></Column>
            <Column field="place" header="Miejscowość" :sortable="true"></Column>
            <Column field="type.name" header="Typ" :sortable="true"></Column>
            <Column headerStyle="width: 8em" bodyStyle="text-align: center">
                <template #body="slotProps">
                    <Button type="button" label="Wybierz" v-on:click="click(slotProps.data.id)"></Button>
                </template>
            </Column>
        </DataTable>
    </div>
</template>

<script>
    import UnitService from "../../services/unit.service";
    import {FilterMatchMode} from 'primevue/api';

    export default {
        name: "SelectUnit",
        inject: ['global', 'unitTypes'],
        data() {
            return {
                content: null,
                filters: {
                    'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
                    'name': {value: null, matchMode: FilterMatchMode.STARTS_WITH}
                },
                loading: true,
            };
        },
        methods: {
            click: function (id) {
                this.$router.push({path: `${this.global.state.instancePatch}/formularze-danych/${this.$route.params.type}/${id}/lista`});
            },
            getList: function () {
                const selectedUnitTypeKey = this.unitTypes.find(o => o.name === this.$route.params.type).unitTypeKey;
                UnitService.getAllUnits().then(
                    (response) => {
                        this.loading = false;
                        if (selectedUnitTypeKey === 'all') {
                            this.content = response.data.items;
                        } else {
                            this.content = response.data.items.filter(item => item.type ? item.type.key === selectedUnitTypeKey : false);
                        }
                    },
                    (error) => {
                        this.content =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                );
            },
        },
        mounted() {
            this.getList();
        },
    };
</script>
